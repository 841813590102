import React from 'react';

import classes from './Alterations.module.css';
import Alters from '../../../assets/alterations.svg';

const Alterations = () => {

    return (
        <div className={classes.containerAlterations}>
            <img alt='AlterationsImage' className={classes.alterationsImage} src={Alters} />
            <br />
        </div>
    );
}

export default Alterations;