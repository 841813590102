import React from 'react';

import FrontImage from '../../../assets/FrontBanner.svg';
import classes from './FrontBanner.module.css';

const FrontBanner = () => {
    return (
        <React.Fragment className={classes.containerFrontBannner}>
            <img className={classes.containerFrontimg} src={FrontImage} alt="Front Banner" />
            <br />
            <br />
        </React.Fragment>
    )
};

export default FrontBanner; 