import React, { Component } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import classes from './MapContainer.module.css';

export class MapContainer extends Component {
    render() {
        const styles = {
            width: '100%',
            height: '100%'
        }
        return (
            <div className={classes.FlexibleContainer}>

                <Map
                    google={this.props.google}
                    initialCenter={{
                        lat: 43.75781,
                        lng: -79.56976
                    }}
                    style={styles}
                    zoom={15}>
                    <Marker
                        title={'The marker`s title will appear as a tooltip.'}
                        name={'Humber Summit Cleaners & Alterations'}
                        position={{ lat: 43.75781, lng: -79.56976 }} />
                </Map>


            </div>

        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAO55yTTt0iHLI0EBXdYFAqcy2qd9PH7bo'
})(MapContainer)