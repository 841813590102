import React from 'react';

import classes from '../Navbar.module.css';

const NavbarLinks = (props) => {
    return (
        Object.keys(props.links).map((link) => {
            return (
                <li key={link}>
                    <p style={{ cursor: "pointer" }} onClick={() => {
                        switch (props.links[link]) {
                            case 'Home':
                                return <React.Fragment>
                                    {window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    })}
                                    {props.show()}
                                </React.Fragment>
                            case 'Dry Cleaning':
                                if (window.innerWidth > 768)
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 900,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                                else
                                    return <React.Fragment>
                                        {window.scrollTo(0, 50)}
                                        {props.show()}
                                    </React.Fragment>
                            case 'Alterations':
                                if (window.innerWidth > 768)
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 2000,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                                else
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 750,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                            case 'Reviews':
                                if (window.innerWidth > 768)
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 3000,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                                else
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 1200,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                            case 'Contact us':
                                if (window.innerWidth > 768)
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 3800,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                                else
                                    return <React.Fragment>
                                        {window.scrollTo({
                                            top: 1600,
                                            behavior: 'smooth'
                                        })}
                                        {props.show()}
                                    </React.Fragment>
                            default:
                                return <React.Fragment>
                                    {window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    })}
                                    {props.show()}
                                </React.Fragment>
                        }
                    }}
                        className={classes.nav_links}>{props.links[link]}</p>
                </li>)
        })
    )

};

export default NavbarLinks;