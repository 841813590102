import React, { Component } from 'react';

import Navbar from './navbar/Navbar';
import FrontBanner from './frontBanner/FrontBanner';
import Drycleaning from './drycleaning/Drycleaning';
import Alterations from './alterations/Alterations';
import Reviews from '../layout/reviews/Reviews';
import ContactUs from '../layout/contactUs/contactUs';
import MapContainer from './googleMaps/MapContainer';
import PriceList from './PriceList';
import { Route, Switch } from 'react-router-dom';

class Layout extends Component {
    state = {
        navbar: {
            home: "Home",
            dryCleaning: "Dry Cleaning",
            alteration: "Alterations",
            reviews: "Reviews",
            contact: "Contact us"
        },
        showNavIcon: false,
        nextReview: 0
    }

    navIconShowHandler = () => {
        this.setState((prevState) => {
            return { showNavIcon: !prevState.showNavIcon };
        });
    }

    nextReviewForwardHandler = () => {
        this.setState((prevState) => {
            if (prevState.nextReview > 1) {
                return { nextReview: 0 }
            }
            else
                return { nextReview: prevState.nextReview + 1 }
        })
    }

    nextReviewBackHandler = () => {
        this.setState((prevState) => {
            if (prevState.nextReview < 1) {
                return { nextReview: 2 }
            }
            else
                return { nextReview: prevState.nextReview - 1 }
        })
    }

    render() {
        const mainPage = (<React.Fragment>
            <Navbar
                navbarHeaders={this.state.navbar}
                shows={this.navIconShowHandler}
                navIconState={this.state.showNavIcon} />
            <FrontBanner />
            <Drycleaning />
            <Alterations />
            <Reviews
                reviewForward={this.nextReviewForwardHandler}
                reviewBack={this.nextReviewBackHandler}
                reviewNum={this.state.nextReview} />
            <ContactUs />
            <MapContainer />
            <main>
                {this.props.children}
            </main>
        </React.Fragment>);
        const priceListPage = (
            <React.Fragment>
                <Navbar
                    navbarHeaders={""}
                    shows={this.navIconShowHandler}
                    navIconState={this.state.showNavIcon} />
                <br />
                <br />
                <br />
                <PriceList />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/" render={() => mainPage} />
                    <Route path="/PriceList" render={() => priceListPage} />
                </Switch>
            </React.Fragment>
        )
    }

};


export default Layout;