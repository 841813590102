import React, { Component } from 'react';

import DryCleaningPrice from '../layout/drycleaning/DryCleaningPrice';

class PriceList extends Component {
    render() {
        return (
            <div>
                <DryCleaningPrice />
            </div>
        );
    }
}

export default PriceList;