import React from 'react';

import classes from './Reviews.module.css';

const Reviews = (props) => {
    let review = "";
    let reviewerName = "";
    if (props.reviewNum === 0) {
        reviewerName = "Darwin Ly";
        review = <p>Great service, great work, done quickly for a fair price.  I had my pants tapered  and some clothes dry cleaned</p>;
    }
    if (props.reviewNum === 1) {
        reviewerName = "Vincent A.";
        review = <p>They hem my pants perfectly and provided excellent customer service!</p>;
    }

    if (props.reviewNum === 2) {
        reviewerName = "Justice";
        review = <p>Lovely owners, had my winter coats cleaned</p>;
    }

    return (
        <div className={classes.root}>
            <br />
            <h1 className={classes.titleReview}>See what our customers are saying about us</h1>
            <div className={classes.containerReviews}>
                <div className={classes.arrowLeft} onClick={props.reviewBack} />
                <div className={classes.card}>
                    <div className={classes.borderBox}>
                        <h2>{reviewerName}</h2>
                        <span className="fa fa-star" style={{ color: "orange" }}></span>
                        <span className="fa fa-star" style={{ color: "orange" }} ></span>
                        <span className="fa fa-star" style={{ color: "orange" }} ></span>
                        <span className="fa fa-star" style={{ color: "orange" }} ></span>
                        <span className="fa fa-star" style={{ color: "orange" }} ></span>
                        {review}
                    </div>
                </div>
                <div className={classes.arrowRight} onClick={props.reviewForward} />
            </div>
            <br />
            <br />
            <br />
        </div>
    );

}

export default Reviews;