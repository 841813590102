import React from 'react';

import { withRouter } from 'react-router-dom';
import classes from './Drycleaning.module.css';
import Coat from '../../../assets/coat.svg';


const Drycleaning = (props) => {
    const routeChange = () => {
        let path = `/PriceList`;
        props.history.push(path);
    }
    return (
        <div className={classes.containerDrycleaningRow}>
            <div className={classes.containerDrycleaning}>
                <h2>Dry Cleaning and Laundry</h2>
                <div className={classes.bob}>
                    <img alt='dryCleaning' className={classes.coatImage} src={Coat} />
                    <div className={classes.bob2} style={{ textAlign: "left" }}>
                        <p>We clean all types of materials and products from leather to cashmere!</p>
                        <p>We also dry clean rugs, curtains and all kind of household items</p>
                        <p>To ensure that all stains are removed we double check every garment before handing it back to you</p>
                        <p>In a Rush? Use our Express option and your clothes will be ready for pick up within 1 business day!</p>
                    </div>
                </div>
                <span>
                    <button className={classes.button} onClick={routeChange}>Full Price List</button>
                </span>
                <p style={{ color: "red" }}>Get 10% off when your dry cleaning bill is over $50 dollars <br />Get 5% off when your dry cleaning bill is over $20 dollars</p>
                <br />
            </div>
        </div>
    );
}
export default withRouter(Drycleaning);