import React, { useLayoutEffect, useEffect, useState } from 'react';

import NavbarLinks from './navbarLinks/NavbarLinks';
import classes from './Navbar.module.css';
import logoImage from '../../../assets/logoCleaners.png';

import { withRouter } from "react-router";


const Navbar = (props) => {
    const [title, setTitle] = useState(<a href="/" className={classes.logo}></a>)
    const navBarClassName = props.navIconState ? classes.main_nav_none : classes.main_nav;
    let navToggle = (<span className={classes.navbar_toggle} id="js-navbar-toggle">
        <i className={"fas fa-bars"} style={{ color: "#0E0E52", fontSize: "25px" }} onClick={props.shows}></i>
    </span>);
    if (props.location.pathname === '/PriceList')
        navToggle = "";

    useEffect(() => {
        // window.pageYOffset;
        window.addEventListener('scroll', displayTitle, { passive: true });
        return () => {
            window.removeEventListener('scroll', displayTitle);
        };
    }, []);

    const displayTitle = () => {
        if (window.innerWidth > 768) {
            if (window.pageYOffset >= 300)
                setTitle(<a href="/" className={classes.logo}> <img className={classes.logoImage} src={logoImage} alt="Front Banner" />Humber Summit Cleaners<br /> & Alterations </a>)
            else
                setTitle(<a href="/" className={classes.logo}></a>)
        } else {
            if (window.pageYOffset >= 75)
                setTitle(<a href="/" className={classes.logo}> <img className={classes.logoImage} src={logoImage} alt="Front Banner" />Humber Summit Cleaners<br /> & Alterations </a>)
            else
                setTitle(<a href="/" className={classes.logo}></a>)
        }
    }

    return (
        <nav className={classes.navbar}>
            {navToggle}
            {title}
            <ul className={navBarClassName} id="js-menu">
                <NavbarLinks links={props.navbarHeaders} show={props.shows} />
                <br />
            </ul>
        </nav>
    );
}

export default withRouter(Navbar);
