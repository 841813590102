import React from 'react';

import classes from './Drycleaning.module.css';

const DryCleaningPrice = () => {
    return (
        <div>
            <div className={classes.containerDrycleaningPrice}>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Pants</h1>
                    <p>Pants $5.49</p>
                    <p>Jeans $5.49</p>
                    <p>Shorts $5.49</p>
                    <p>Skirt $7.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Shirts</h1>
                    <p>T-shirts $4.99</p>
                    <p>Shirts $3.99</p>
                    <p>Golf Shirt $5.49</p>
                    <p>Blouse $7.99</p>
                    <p>Custom Shirt $9.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Suits</h1>
                    <p>Suit 2PC $15.99</p>
                    <p>Suit 3PC $17.99</p>
                    <p>Jumpsuit $14.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Dress</h1>
                    <p>Dress $14.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Coat</h1>
                    <p>Spring Coat $14.99</p>
                    <p>Winter Coat $34.99</p>
                    <p>Canada Goose $49.99</p>
                    <p>Rain Coat $19.99</p>
                    <p>Lab Coat $9.99</p>
                    <p>Robe $8.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Upper wear</h1>
                    <p>Jacket/Blazer $11.99</p>
                    <p>Ski Jacket $11.99</p>
                    <p>Sweater $7.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Wedding</h1>
                    <p>Bride Maid's Gown $94.99</p>
                    <p>Gloves $7.99</p>
                    <p>Train $14.99</p>
                    <p>Wedding Gown $179.99</p>
                    <p>Wedding Gown Box $74.99</p>
                </div>
                <div className={classes.DryCleaningPricePadding}>
                    <h1>Household</h1>
                    <p>Comforter (S) $22.99</p>
                    <p>Comforter (D) $29.99</p>
                    <p>Comforter (Q) $34.99</p>
                    <p>Comforter (K) $39.99</p>
                </div>
            </div>
        </div>

    );
}

export default DryCleaningPrice;